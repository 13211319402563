/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * When to generate notifications.
 * 
 * - `NONE`: Never notify
 * - `START`: Notify alert starts
 */
export enum AlertNotificationBehavior {
  NONE = "NONE",
  START = "START",
}

/**
 * Represents the type of an attribute.
 */
export enum AttributeConfigurationType {
  CalculationAttributeConfiguration = "CalculationAttributeConfiguration",
  CheckboxAttributeConfiguration = "CheckboxAttributeConfiguration",
  SelectAttributeConfiguration = "SelectAttributeConfiguration",
  SingleLineTextAttributeConfiguration = "SingleLineTextAttributeConfiguration",
}

/**
 * The product type options allowed for enterprise project object products.
 */
export enum AuditSubjectType {
  PROJECT = "PROJECT",
  PROJECt_OBJECT_LIST_ITEM = "PROJECt_OBJECT_LIST_ITEM",
}

/**
 * Supported Comgy Device types.
 */
export enum ComgyApiDeviceType {
  METER = "METER",
  METER_MODULE = "METER_MODULE",
  SENSOR = "SENSOR",
}

/**
 * Consumption Data exchange types suppported by the platform.
 */
export enum ConsumptionDataExchangeType {
  Allpas = "Allpas",
  Asko = "Asko",
  EAD_Buchholz = "EAD_Buchholz",
  EAD_Zeulenroda = "EAD_Zeulenroda",
  Ibaenergie = "Ibaenergie",
  MevivoEco = "MevivoEco",
  VarysMDL = "VarysMDL",
  VarysSW = "VarysSW",
}

/**
 * Supported EnerIQ types for system component sources.
 */
export enum EnerIqMeasurementSourceType {
  BOTTOM_TEMPERATURE = "BOTTOM_TEMPERATURE",
  CIRCULATION_TEMPERATURE = "CIRCULATION_TEMPERATURE",
  CIRCULATION_TEMPERATURE_IN = "CIRCULATION_TEMPERATURE_IN",
  CIRCULATION_TEMPERATURE_OUT = "CIRCULATION_TEMPERATURE_OUT",
  FUEL_INPUT_QUANTITY = "FUEL_INPUT_QUANTITY",
  HEAT_METER_POWER = "HEAT_METER_POWER",
  HEAT_PRODUCTION_QUANTITY = "HEAT_PRODUCTION_QUANTITY",
  HEAT_QUANTITY = "HEAT_QUANTITY",
  MIDDLE_TEMPERATURE = "MIDDLE_TEMPERATURE",
  PRIMARY_TEMPERATURE_IN = "PRIMARY_TEMPERATURE_IN",
  PRIMARY_TEMPERATURE_OUT = "PRIMARY_TEMPERATURE_OUT",
  QUANTITY = "QUANTITY",
  SECONDARY_TEMPERATURE_IN = "SECONDARY_TEMPERATURE_IN",
  SECONDARY_TEMPERATURE_OUT = "SECONDARY_TEMPERATURE_OUT",
  TEMPERATURE_IN = "TEMPERATURE_IN",
  TEMPERATURE_OUT = "TEMPERATURE_OUT",
  TERTIARY_TEMPERATURE_IN = "TERTIARY_TEMPERATURE_IN",
  TERTIARY_TEMPERATURE_OUT = "TERTIARY_TEMPERATURE_OUT",
  TOP_TEMPERATURE = "TOP_TEMPERATURE",
  WARM_WATER_TEMPERATURE = "WARM_WATER_TEMPERATURE",
}

/**
 * Supported EnerIQ types for system components.
 */
export enum EnerIqMeasurementSystemComponentType {
  BOILER = "BOILER",
  BUFFER = "BUFFER",
  COLD_WATER_METER = "COLD_WATER_METER",
  ELECTRICITY_METER = "ELECTRICITY_METER",
  GAS_METER = "GAS_METER",
  HEATING_CIRCUIT = "HEATING_CIRCUIT",
  TELE_HEATING = "TELE_HEATING",
  WARM_WATER_METER = "WARM_WATER_METER",
  WATER_HEATING = "WATER_HEATING",
}

/**
 * Energy Sources for HeatingSystem.
 */
export enum EnergySource {
  ELECTRICITY = "ELECTRICITY",
  GAS = "GAS",
  HEAT = "HEAT",
  OIL = "OIL",
}

/**
 * The product type options allowed for enterprise project object products.
 */
export enum EnterpriseProjectAuditEventType {
  CREATE_BUILDINGS = "CREATE_BUILDINGS",
  CREATE_PROJECT = "CREATE_PROJECT",
  DOWNLOAD_STANDORTLISTE = "DOWNLOAD_STANDORTLISTE",
  GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS",
  GET_PROJECT_DRAFT = "GET_PROJECT_DRAFT",
  LIST_PROJECTS = "LIST_PROJECTS",
  UPDATE_BUILDING = "UPDATE_BUILDING",
  UPDATE_PROJECT_DRAFT = "UPDATE_PROJECT_DRAFT",
  UPDATE_PROJECT_STATUS = "UPDATE_PROJECT_STATUS",
}

/**
 * The members of this enum equal the type names of EnterpriseProjectBuildingStatus implementors.
 */
export enum EnterpriseProjectBuildingStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  DEPLOYMENT_DONE = "DEPLOYMENT_DONE",
  INFORMATION_MISSING = "INFORMATION_MISSING",
  IN_DEPLOYMENT = "IN_DEPLOYMENT",
  IN_QA = "IN_QA",
  ORDER_CONFIRMED = "ORDER_CONFIRMED",
  PROPERTY_CREATED = "PROPERTY_CREATED",
  PROPERTY_LIST_FINAL = "PROPERTY_LIST_FINAL",
  QUALITY_MANAGEMENT = "QUALITY_MANAGEMENT",
}

/**
 * The members of this enum equal the type names of EnterpriseProjectObjectProduct implementors.
 */
export enum EnterpriseProjectObjectProduct {
  INFRASTRUCTURE_VARIANT = "INFRASTRUCTURE_VARIANT",
  IW_CLOUD = "IW_CLOUD",
}

/**
 * The product type options allowed for enterprise project object products.
 */
export enum EnterpriseProjectObjectProductOption {
  BASIS = "BASIS",
  BASIS_PLUS = "BASIS_PLUS",
  PRO = "PRO",
  STANDARD = "STANDARD",
  START = "START",
}

/**
 * The members of this enum equal the type names of EnterpriseProjectObjectUseCase implementors.
 */
export enum EnterpriseProjectObjectUseCase {
  CONSUMPTION_DATA_ACQUISITION = "CONSUMPTION_DATA_ACQUISITION",
  CONSUMPTION_INFORMATION_DURING_THE_YEAR = "CONSUMPTION_INFORMATION_DURING_THE_YEAR",
  ELEVATOR_MONITORING = "ELEVATOR_MONITORING",
  HEATING_MONITORING = "HEATING_MONITORING",
  HEATING_PROFESSIONAL = "HEATING_PROFESSIONAL",
  REMOTE_METER_READING = "REMOTE_METER_READING",
  SERVICE_MANAGEMENT = "SERVICE_MANAGEMENT",
  SMART_ELEVATOR_MAINTENANCE = "SMART_ELEVATOR_MAINTENANCE",
  SMG = "SMG",
  SMOKE_ALARM_MONITORING = "SMOKE_ALARM_MONITORING",
  TGA_DIGITIZATION = "TGA_DIGITIZATION",
  TGA_INTEGRATION = "TGA_INTEGRATION",
  TGA_MONITORING = "TGA_MONITORING",
}

/**
 * The members of this enum equal the type names of EnterpriseProjectServiceType implementors.
 */
export enum EnterpriseProjectServiceType {
  GK_HUB_G = "GK_HUB_G",
  GK_HUB_L_CABLE = "GK_HUB_L_CABLE",
  GK_HUB_L_GDSP_MOBILFUNK = "GK_HUB_L_GDSP_MOBILFUNK",
}

/**
 * The members of this enum equal the type names of EnterpriseProjectStatus implementors.
 */
export enum EnterpriseProjectStatus {
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE",
  CONFIRMED = "CONFIRMED",
  PLANNED = "PLANNED",
}

/**
 * The types of projects that can be created.
 */
export enum EnterpriseProjectType {
  NEW_ORDER = "NEW_ORDER",
  NEW_PRODUCT_NEW_USE_CASE = "NEW_PRODUCT_NEW_USE_CASE",
  NEW_PRODUCT_ORIGINAL_USE_CASE = "NEW_PRODUCT_ORIGINAL_USE_CASE",
  ORIGINAL = "ORIGINAL",
  PRICING_CHANGES = "PRICING_CHANGES",
}

export enum ExportPresetType {
  DEFAULT = "DEFAULT",
  STEFFENS = "STEFFENS",
}

/**
 * Health of a gateway.
 * 
 * - `UNKNOWN`
 * - `CRITICAL`
 * - `DEGRADED`
 * - `OK`
 */
export enum GatewayHealth {
  CRITICAL = "CRITICAL",
  DEGRADED = "DEGRADED",
  OK = "OK",
  UNKNOWN = "UNKNOWN",
}

export enum GroupRole {
  APP_ACT_AQUA = "APP_ACT_AQUA",
  APP_ADMIN = "APP_ADMIN",
  APP_CUSTOMER = "APP_CUSTOMER",
  APP_EED = "APP_EED",
  APP_ENTERPRISE_PROJECT = "APP_ENTERPRISE_PROJECT",
  APP_ENTERPRISE_TEST_USER = "APP_ENTERPRISE_TEST_USER",
  APP_EXPORT = "APP_EXPORT",
  APP_HEATING_SYSTEM = "APP_HEATING_SYSTEM",
  APP_HEATING_SYSTEM_SETTINGS = "APP_HEATING_SYSTEM_SETTINGS",
  APP_HELP = "APP_HELP",
  APP_HOME = "APP_HOME",
  APP_MAINTENANCE = "APP_MAINTENANCE",
  APP_MANAGER = "APP_MANAGER",
  APP_NOTIFICATION = "APP_NOTIFICATION",
  APP_SALES = "APP_SALES",
  APP_SMART_DEVICES = "APP_SMART_DEVICES",
  APP_SMOKE_DETECTOR = "APP_SMOKE_DETECTOR",
  APP_SUITE_FIVE = "APP_SUITE_FIVE",
  APP_SUPPORT = "APP_SUPPORT",
  APP_USER_ACCOUNT = "APP_USER_ACCOUNT",
  APP_WIFI = "APP_WIFI",
}

export enum GroupRolePermission {
  APP_ADMIN_DATA_CONSUMPTION_POLL = "APP_ADMIN_DATA_CONSUMPTION_POLL",
  APP_ADMIN_ENTERPRISE_PROJECT_CREATE = "APP_ADMIN_ENTERPRISE_PROJECT_CREATE",
  APP_ADMIN_PERMISSION_ALERT_RULE = "APP_ADMIN_PERMISSION_ALERT_RULE",
  APP_ADMIN_PERMISSION_ATTRIBUTE_DEFINITION_TEMPLATE = "APP_ADMIN_PERMISSION_ATTRIBUTE_DEFINITION_TEMPLATE",
  APP_ADMIN_PERMISSION_CONTRACT = "APP_ADMIN_PERMISSION_CONTRACT",
  APP_ADMIN_PERMISSION_CUSTOMER = "APP_ADMIN_PERMISSION_CUSTOMER",
  APP_ADMIN_PERMISSION_FLAT_CODE = "APP_ADMIN_PERMISSION_FLAT_CODE",
  APP_ADMIN_PERMISSION_GROUP = "APP_ADMIN_PERMISSION_GROUP",
  APP_ADMIN_PERMISSION_USER = "APP_ADMIN_PERMISSION_USER",
  APP_CUSTOMER_ACTIVITY = "APP_CUSTOMER_ACTIVITY",
  APP_CUSTOMER_ACTIVITY_TREE_NODE_BOOKMARK = "APP_CUSTOMER_ACTIVITY_TREE_NODE_BOOKMARK",
  APP_CUSTOMER_ALERT_RULE_DEPLOYMENT = "APP_CUSTOMER_ALERT_RULE_DEPLOYMENT",
  APP_CUSTOMER_ATTRIBUTE_DEFINITION = "APP_CUSTOMER_ATTRIBUTE_DEFINITION",
  APP_CUSTOMER_DATA_CONSUMPTION_POLL_AUDIT = "APP_CUSTOMER_DATA_CONSUMPTION_POLL_AUDIT",
  APP_CUSTOMER_FORM = "APP_CUSTOMER_FORM",
  APP_CUSTOMER_NOTIFICATION_AUDIT_LOG = "APP_CUSTOMER_NOTIFICATION_AUDIT_LOG",
  APP_CUSTOMER_SERVICE_PROVIDER = "APP_CUSTOMER_SERVICE_PROVIDER",
  APP_CUSTOMER_VENDOR_API = "APP_CUSTOMER_VENDOR_API",
  APP_ENTERPRISE_SINGLE_L_EDIT = "APP_ENTERPRISE_SINGLE_L_EDIT",
  APP_EXPORT_CREATE_EXPORT = "APP_EXPORT_CREATE_EXPORT",
  APP_EXPORT_SPOT = "APP_EXPORT_SPOT",
  APP_HEATING_SYSTEM_ADVANCED_VIEW = "APP_HEATING_SYSTEM_ADVANCED_VIEW",
  APP_HEATING_SYSTEM_ALERT = "APP_HEATING_SYSTEM_ALERT",
  APP_HEATING_SYSTEM_ALERT_V1 = "APP_HEATING_SYSTEM_ALERT_V1",
  APP_HEATING_SYSTEM_DIRECTORY_SPOTS = "APP_HEATING_SYSTEM_DIRECTORY_SPOTS",
  APP_HEATING_SYSTEM_LIST = "APP_HEATING_SYSTEM_LIST",
  APP_HEATING_SYSTEM_LIST_V1 = "APP_HEATING_SYSTEM_LIST_V1",
  APP_HEATING_SYSTEM_LIST_V2 = "APP_HEATING_SYSTEM_LIST_V2",
  APP_HEATING_SYSTEM_METADATA = "APP_HEATING_SYSTEM_METADATA",
  APP_HEATING_SYSTEM_NOTIFICATION = "APP_HEATING_SYSTEM_NOTIFICATION",
  APP_HEATING_SYSTEM_NOTIFICATION_V1 = "APP_HEATING_SYSTEM_NOTIFICATION_V1",
  APP_HEATING_SYSTEM_SETTINGS = "APP_HEATING_SYSTEM_SETTINGS",
  APP_HEATING_SYSTEM_SETTINGS_V2 = "APP_HEATING_SYSTEM_SETTINGS_V2",
  APP_HEATING_SYSTEM_TREE_NODE = "APP_HEATING_SYSTEM_TREE_NODE",
  APP_MAINTENANCE_ALERT = "APP_MAINTENANCE_ALERT",
  APP_MAINTENANCE_GATEWAY = "APP_MAINTENANCE_GATEWAY",
  APP_MANAGER_ACTIVITY_LOG_ENTRY = "APP_MANAGER_ACTIVITY_LOG_ENTRY",
  APP_MANAGER_ALERT = "APP_MANAGER_ALERT",
  APP_MANAGER_APARTMENT = "APP_MANAGER_APARTMENT",
  APP_MANAGER_ATTRIBUTE = "APP_MANAGER_ATTRIBUTE",
  APP_MANAGER_FILE = "APP_MANAGER_FILE",
  APP_MANAGER_GATEWAY = "APP_MANAGER_GATEWAY",
  APP_MANAGER_GROUD_PLAN = "APP_MANAGER_GROUD_PLAN",
  APP_MANAGER_INVENTORY = "APP_MANAGER_INVENTORY",
  APP_MANAGER_PROPERTY = "APP_MANAGER_PROPERTY",
  APP_MANAGER_SPOT = "APP_MANAGER_SPOT",
  APP_SMOKE_DETECTOR_PROTOCOL = "APP_SMOKE_DETECTOR_PROTOCOL",
  APP_SMOKE_DETECTOR_SPOT = "APP_SMOKE_DETECTOR_SPOT",
  APP_SMOKE_DETECTOR_TREE_NODE = "APP_SMOKE_DETECTOR_TREE_NODE",
  APP_USER_ACCOUNT_GENERAL = "APP_USER_ACCOUNT_GENERAL",
  APP_USER_ACCOUNT_NOTIFICATION_SETTING = "APP_USER_ACCOUNT_NOTIFICATION_SETTING",
}

/**
 * Health of a heating system.
 * 
 * - `INACTIVE`
 * - `UNKNOWN`
 * - `CRITICAL`
 * - `DEGRADED`
 * - `OK`
 */
export enum HeatingSystemHealth {
  CRITICAL = "CRITICAL",
  DEGRADED = "DEGRADED",
  INACTIVE = "INACTIVE",
  OK = "OK",
  UNKNOWN = "UNKNOWN",
}

export enum IntervalUnit {
  Day = "Day",
  Month = "Month",
  QUARTER_HOURLY = "QUARTER_HOURLY",
  Week = "Week",
  Year = "Year",
}

/**
 * Switch commands for a lamp.
 * 
 * - `LIGHT_ON`: On
 * - `LIGHT_OFF`: Off
 * - `LIGHT_TRIGGER`: Trigger
 */
export enum LampCommandName {
  LIGHT_OFF = "LIGHT_OFF",
  LIGHT_ON = "LIGHT_ON",
  LIGHT_TRIGGER = "LIGHT_TRIGGER",
}

/**
 * MFA state of a user.
 */
export enum MFAState {
  ACTIVE = "ACTIVE",
  CONFIGURING = "CONFIGURING",
  INACTIVE = "INACTIVE",
}

/**
 * A function to aggregate a single metric across time.
 * 
 * - `LAST`: Last value in group
 * - `SUM`: Sum values in group
 */
export enum MetricResolutionAggregator {
  FIRST = "FIRST",
  LAST = "LAST",
  MAX = "MAX",
  MEAN = "MEAN",
  MIN = "MIN",
  SUM = "SUM",
}

/**
 * The fill behavior for missing points.
 * 
 * - `NONE`: Does not fill missing points, e.g. there may be larger gaps
 *   between points than the resolution interval duration.
 * - `PREVIOUS`: Fills missing points with the value from the previous
 *   interval.
 */
export enum MetricResolutionFill {
  NONE = "NONE",
  PREVIOUS = "PREVIOUS",
}

/**
 * Which edge of the time window to use as the time of the resulting aggregated
 * point.
 * 
 * - `START`: Use the beginning of the time window.
 * - `STOP`: Use the end of the time window.
 */
export enum MetricResolutionTimeSource {
  START = "START",
  STOP = "STOP",
}

/**
 * A function to aggregate a metric across spots.
 * 
 * - `MIN`
 * - `MAX`
 * - `SUM`
 * - `MEAN`
 * - `COUNT`
 */
export enum MetricSpotAggregator {
  COUNT = "COUNT",
  MAX = "MAX",
  MEAN = "MEAN",
  MIN = "MIN",
  SUM = "SUM",
}

/**
 * Possible intervals in which notification emails are sent.
 * 
 * - `IMMEDIATELY`
 * - `DAILY`
 * - `WEEKLY`
 */
export enum NotificationInterval {
  DAILY = "DAILY",
  IMMEDIATELY = "IMMEDIATELY",
  WEEKLY = "WEEKLY",
}

export enum NotificationType {
  AlertNotification = "AlertNotification",
  GatewayHealthNotification = "GatewayHealthNotification",
  HeatingSystemNotification = "HeatingSystemNotification",
  SpotHealthNotification = "SpotHealthNotification",
}

/**
 * Ordering of a collection.
 * 
 * - `ASC`: Ascending
 * - `DESC`: Descending
 */
export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PropertyFloorType {
  OVERGROUND = "OVERGROUND",
  ROOF = "ROOF",
  UNDERGROUND = "UNDERGROUND",
}

/**
 * - `APARTMENT`
 * - `ROOM`
 * - `STAIRWELL`
 */
export enum PropertySubdivisionVisualizationHint {
  APARTMENT = "APARTMENT",
  ROOM = "ROOM",
  STAIRWELL = "STAIRWELL",
}

/**
 * Auth method of a service provider.
 * 
 * - `PIN`
 * - `TOKEN`
 */
export enum ServiceProviderAuthMethod {
  PIN = "PIN",
  TOKEN = "TOKEN",
}

export enum ServiceProviderStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/**
 * Health of a spot.
 * 
 * - `INACTIVE`: A spot with no device installed
 * - `UNKNOWN`
 * - `CRITICAL`
 * - `DEGRADED`
 * - `OK`
 */
export enum SpotHealth {
  CRITICAL = "CRITICAL",
  DEGRADED = "DEGRADED",
  INACTIVE = "INACTIVE",
  OK = "OK",
  UNKNOWN = "UNKNOWN",
}

/**
 * Roles of a spot.
 */
export enum SpotRole {
  ACT_AQUA_PIPE = "ACT_AQUA_PIPE",
  AIR_QUALITY = "AIR_QUALITY",
  AUFZUGHELDEN_DEVICE = "AUFZUGHELDEN_DEVICE",
  BOILER_PLANT = "BOILER_PLANT",
  CEILING_LAMP = "CEILING_LAMP",
  DOOR_CONTACT = "DOOR_CONTACT",
  DORMAKABA_COMPONENT = "DORMAKABA_COMPONENT",
  DUAL_HEATING = "DUAL_HEATING",
  DUAL_TEMPERATURE = "DUAL_TEMPERATURE",
  DUAL_WATER = "DUAL_WATER",
  ELECTRICITY_METER_ANALOG = "ELECTRICITY_METER_ANALOG",
  ELECTRICITY_METER_DIGITAL = "ELECTRICITY_METER_DIGITAL",
  FRONIUS_INVERTER = "FRONIUS_INVERTER",
  GAS_METER = "GAS_METER",
  GATEWAY_LIGHT_SWITCH = "GATEWAY_LIGHT_SWITCH",
  GENERAL_METER_ELECTRICITY_METER_ANALOG = "GENERAL_METER_ELECTRICITY_METER_ANALOG",
  GENERAL_METER_ELECTRICITY_METER_DIGITAL = "GENERAL_METER_ELECTRICITY_METER_DIGITAL",
  GENERAL_METER_GAS_METER = "GENERAL_METER_GAS_METER",
  GENERAL_METER_HEAT_METER_COUNTER = "GENERAL_METER_HEAT_METER_COUNTER",
  GENERAL_METER_WATER_METER_COLD = "GENERAL_METER_WATER_METER_COLD",
  GENERAL_METER_WATER_METER_HOT = "GENERAL_METER_WATER_METER_HOT",
  GLASBREAK_SENSOR = "GLASBREAK_SENSOR",
  HEATING = "HEATING",
  HEATING_CONTROL = "HEATING_CONTROL",
  HEAT_COST_ALLOCATOR = "HEAT_COST_ALLOCATOR",
  HEAT_METER_COUNTER = "HEAT_METER_COUNTER",
  HEAT_METER_TEMPERATURE = "HEAT_METER_TEMPERATURE",
  INDIVIDUAL_METER_ELECTRICITY_METER_ANALOG = "INDIVIDUAL_METER_ELECTRICITY_METER_ANALOG",
  INDIVIDUAL_METER_ELECTRICITY_METER_DIGITAL = "INDIVIDUAL_METER_ELECTRICITY_METER_DIGITAL",
  INDIVIDUAL_METER_GAS_METER = "INDIVIDUAL_METER_GAS_METER",
  INDIVIDUAL_METER_HEAT_METER_COUNTER = "INDIVIDUAL_METER_HEAT_METER_COUNTER",
  INDIVIDUAL_METER_WATER_METER_COLD = "INDIVIDUAL_METER_WATER_METER_COLD",
  INDIVIDUAL_METER_WATER_METER_HOT = "INDIVIDUAL_METER_WATER_METER_HOT",
  KONE_LIFT = "KONE_LIFT",
  LEAKAGE_DETECTOR = "LEAKAGE_DETECTOR",
  LIFTING_STATION = "LIFTING_STATION",
  LIGHT_SWITCH = "LIGHT_SWITCH",
  MODBUS_CONVERTER = "MODBUS_CONVERTER",
  MONOXID_SENSOR = "MONOXID_SENSOR",
  MOTION_DETECTOR = "MOTION_DETECTOR",
  MOTION_DETECTOR_LIGHT_SWITCH = "MOTION_DETECTOR_LIGHT_SWITCH",
  NETWORK_ANALYZER = "NETWORK_ANALYZER",
  NUKI_BOX = "NUKI_BOX",
  NUKI_OPENER = "NUKI_OPENER",
  NUKI_SMARTLOCK = "NUKI_SMARTLOCK",
  OCCUPANCY_DETECTOR = "OCCUPANCY_DETECTOR",
  OIL_FOX_TANK = "OIL_FOX_TANK",
  OUTSIDE_TEMPERATURE = "OUTSIDE_TEMPERATURE",
  PUMP = "PUMP",
  QUAD_HEATING = "QUAD_HEATING",
  QUAD_TEMPERATURE = "QUAD_TEMPERATURE",
  QUAD_WATER = "QUAD_WATER",
  SCHALTKONTAKT = "SCHALTKONTAKT",
  SECTION_METER_ELECTRICITY_METER_ANALOG = "SECTION_METER_ELECTRICITY_METER_ANALOG",
  SECTION_METER_ELECTRICITY_METER_DIGITAL = "SECTION_METER_ELECTRICITY_METER_DIGITAL",
  SECTION_METER_GAS_METER = "SECTION_METER_GAS_METER",
  SECTION_METER_HEAT_METER_COUNTER = "SECTION_METER_HEAT_METER_COUNTER",
  SECTION_METER_WATER_METER_COLD = "SECTION_METER_WATER_METER_COLD",
  SECTION_METER_WATER_METER_HOT = "SECTION_METER_WATER_METER_HOT",
  SMART_CLIMATE_SENSOR = "SMART_CLIMATE_SENSOR",
  SMART_PLUG = "SMART_PLUG",
  SMOKE_DETECTOR = "SMOKE_DETECTOR",
  TEMPERATURE = "TEMPERATURE",
  TEMPERATURE_HUMIDITY = "TEMPERATURE_HUMIDITY",
  THERMOCOUPLES_RETURN_FLOW = "THERMOCOUPLES_RETURN_FLOW",
  THERMOCOUPLES_SUPPLY_FLOW = "THERMOCOUPLES_SUPPLY_FLOW",
  UNKNOWN = "UNKNOWN",
  VIESSMANN_HEATING_SYSTEM = "VIESSMANN_HEATING_SYSTEM",
  WATER = "WATER",
  WATER_COST_ALLOCATOR = "WATER_COST_ALLOCATOR",
  WATER_METER_COLD = "WATER_METER_COLD",
  WATER_METER_HOT = "WATER_METER_HOT",
  WINDOW_CONTACT = "WINDOW_CONTACT",
  WM_BUS_REPEATER = "WM_BUS_REPEATER",
  ZIGBEE_REPEATER = "ZIGBEE_REPEATER",
}

/**
 * The export preset timeframe
 * 
 * - `YEARLY`: Yearly,
 * - `MONTHLY`: Monthly,
 * - `WEEKLY`: Weekly,
 * - `CUSTOM`: Custom,
 * - `QUARTER_HOURLY`: P15MIN
 */
export enum TimeframeType {
  CUSTOM = "CUSTOM",
  MONTHLY = "MONTHLY",
  QUARTER_HOURLY = "QUARTER_HOURLY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

/**
 * The members of this enum equal the type names of TreeNodeClassification implementors.
 */
export enum TreeNodeClassification {
  Gebaude = "Gebaude",
  Liegenschaft = "Liegenschaft",
}

/**
 * How the given IDs are used for filtering the set of tree nodes.
 */
export enum TreeNodeIdFilterStrategy {
  ANCESTOR_OR_SELF = "ANCESTOR_OR_SELF",
  DESCENDANT_OR_SELF = "DESCENDANT_OR_SELF",
  PARENT = "PARENT",
  SELF = "SELF",
}

/**
 * The members of this enum equal the type names of TreeNode implementors.
 */
export enum TreeNodeType {
  Directory = "Directory",
  Gateway = "Gateway",
  Property = "Property",
  PropertyGroup = "PropertyGroup",
  PropertySpot = "PropertySpot",
  PropertySubdivision = "PropertySubdivision",
  RootDirectory = "RootDirectory",
  Spot = "Spot",
  TreeNodeGroup = "TreeNodeGroup",
}

/**
 * The known vendor API types
 */
export enum VendorApiType {
  AareonApi = "AareonApi",
  AlertMailConfiguration = "AlertMailConfiguration",
  AufzugheldenApi = "AufzugheldenApi",
  BOSCH = "BOSCH",
  ComgyApi = "ComgyApi",
  ConsumptionDataExchange = "ConsumptionDataExchange",
  DORMAKABA = "DORMAKABA",
  EnerIqApi = "EnerIqApi",
  ExternalSftpConfiguration = "ExternalSftpConfiguration",
  GK40Heizungsprofi = "GK40Heizungsprofi",
  IvmApi = "IvmApi",
  IvmConsumptionApi = "IvmConsumptionApi",
  IvmNotificationApi = "IvmNotificationApi",
  KONE = "KONE",
  NukiApi = "NukiApi",
  OIL_FOX = "OIL_FOX",
  ViessmannApi = "ViessmannApi",
}

export interface AddAareonApiInput {
  customerId: string;
  kafkaTopic: string;
}

export interface AddActivityCheckInScheduleInput {
  activityId: ActivityId;
  amount: number;
  daysOfWeek?: number[] | null;
  endDate?: DateTime | null;
  notifyOnMissedLogEntry: boolean;
  startDate: DateTime;
  treeNodeIds: string[];
  treeNodeType: TreeNodeType;
  unit: IntervalUnit;
}

export interface AddActivityInput {
  customerId: string;
  name: string;
}

export interface AddAlertMailConfigurationInput {
  customerId: string;
  label: string;
}

export interface AddAlertRuleDeploymentInput {
  customerId: string;
  name: string;
  ruleId: AlertRuleId;
}

export interface AddAlertRuleInput {
  name: string;
}

export interface AddAttributeDefinitionInput {
  calculations?: JSONObject | null;
  customerId: string;
  description?: string | null;
  inheritableTreeNodeTypes?: TreeNodeType[] | null;
  name: string;
  options?: string[] | null;
  treeNodeTypes: TreeNodeType[];
  type: AttributeConfigurationType;
}

export interface AddAttributeDefinitionTemplateInput {
  addAttributeOnCustomerAdded?: boolean | null;
  description?: string | null;
  name: string;
  options?: string[] | null;
  treeNodeTypes: TreeNodeType[];
  type: AttributeConfigurationType;
}

export interface AddAufzugheldenApiInput {
  customerId: string;
  password: string;
  username: string;
}

export interface AddComgyApiInput {
  customerId: string;
  label: string;
  token: string;
}

export interface AddConsumptionDataExchangeConfigurationInput {
  customerId: string;
  label: string;
  type: ConsumptionDataExchangeType;
}

export interface AddContractInput {
  customerId: string;
  externalId: string;
}

export interface AddCustomerInput {
  externalId?: string | null;
  name: string;
  rootDirectoryName?: string | null;
}

export interface AddEnerIqApiInput {
  customerId: string;
  maxConnectedHeatingSystems?: number | null;
  treeNodeIds?: string[] | null;
}

export interface AddExportConfigurationInput {
  customerId: string;
  exportInterval: IntervalUnit;
  exportPresetId: string;
  externalSftpConfigurationId?: VendorApiId | null;
  mailAddress?: string | null;
  sftpPath?: string | null;
  treeNodeIds: string[];
}

export interface AddExportPresetInput {
  customerId: string;
  name: string;
}

export interface AddExternalSftpConfigurationInput {
  customerId: string;
  host: string;
  label: string;
  password: string;
  port: number;
  username: string;
}

export interface AddFlatCodesInput {
  resources: AddFlatCodesResourceInput[];
}

export interface AddFlatCodesResourceInput {
  accessDate?: DateTime | null;
  resource: string;
  resourceType: string;
}

export interface AddFormInput {
  customerId: string;
  title: string;
}

export interface AddGK40HeizungsprofiConfigurationInput {
  customerId: string;
  label: string;
}

/**
 * The data object describing the group to add.
 */
export interface AddGroupInput {
  name: string;
}

export interface AddHeatingSystemConsumerInput {
  consumerId: string;
  heatingSystemId: string;
}

export interface AddHeatingSystemInput {
  name: string;
  siteId: string;
}

export interface AddHeatingSystemMeasurementInput {
  consumerId: string;
  heatingSystemId: string;
  metricName: string;
  name: string;
  spotId: string;
}

export interface AddHeatingSystemSettingInput {
  associatedMeasurementIds: string[];
  heatingSystemId: HeatingSystemId;
  metricName: string;
  name: string;
  spotId: string;
}

export interface AddIvmApiInput {
  customerId: string;
  label: string;
}

export interface AddNukiApiInput {
  customerId: string;
  label: string;
}

export interface AddServiceProviderAuthMethodInput {
  type: ServiceProviderAuthMethod;
  value: string;
}

export interface AddServiceProviderInput {
  auth?: AddServiceProviderAuthMethodInput | null;
  checkOutInterval?: Duration | null;
  customerId: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  name: string;
  notes?: string | null;
  phoneNumber?: string | null;
  treeNodeIds: string[];
}

export interface AddServiceProvidersActivityInput {
  activityId: string;
  serviceProviderIds: string[];
}

export interface AddSpotInput {
  name: string;
  notes?: string | null;
  parentId: string;
  role: SpotRole;
  roomName?: string | null;
}

export interface AddSuite5CodeInput {
  code: string;
  treeNodeId: string;
}

export interface AddTemplateBasedAttributeDefinitionsInput {
  customerIds: string[];
  templateId: AttributeDefinitionTemplateId;
}

export interface AddTreeNodeDataInput {
  children?: AddTreeNodeDataInput[] | null;
  classification?: string | null;
  contractId?: string | null;
  floorLevel?: number | null;
  name: string;
  order?: number | null;
  type: TreeNodeType;
}

export interface AddTreeNodeInput {
  parentId: string;
  treeNode: AddTreeNodeDataInput;
}

/**
 * The data object describing the user to add.
 */
export interface AddUserInput {
  email: string;
  firstName: string;
  lastName: string;
}

export interface AddViessmannApiInput {
  customerId: string;
  label: string;
  refreshToken: string;
}

export interface AddressInput {
  country?: string | null;
  locality?: string | null;
  postal?: string | null;
  street?: string | null;
}

export interface ApplyGatewayConfigurationInput {
  treeNodeId: string;
}

export interface AssignNotificationInput {
  assigneeId?: string | null;
  notificationId: string;
}

export interface BookmarkActivityTreeNodeInput {
  activityIds: ActivityId[];
  token?: string | null;
  treeNodeId: string;
}

export interface ConfigureLatestMetricsAlertRuleStrategyInput {
  condition: ConditionExpression;
  duration: Duration;
  id: AlertRuleId;
  period?: DateFilterExpression | null;
}

export interface ConfigureNotificationEmailForUserInput {
  alertCategoryIds: AlertCategoryId[];
  customerId: string;
  enabled: boolean;
  gatewayHealths: GatewayHealth[];
  heatingSystem: boolean;
  interval: NotificationInterval;
  missedActivityLogEntry: boolean;
  smsNotificationsEnabled?: boolean | null;
  spotHealths: SpotHealth[];
}

export interface ConfigureNotificationEmailsForUserInput {
  configurations: ConfigureNotificationEmailForUserInput[];
  userId?: string | null;
}

export interface ConfigureNotificationRetentionPolicyInput {
  customerId: string;
  dropAfter?: Duration | null;
  scheduleInterval?: Duration | null;
  scheduled?: boolean | null;
}

export interface ConfigureUsersOTPInput {
  userIds: string[];
  value: boolean;
}

export interface ConnectAareonHeatingSystemInput {
  aareonApiId: VendorApiId;
  heatingSystemId: HeatingSystemId;
}

export interface ConnectComgyDeviceInput {
  comgyApiId: VendorApiId;
  deviceId: string;
  deviceType: ComgyApiDeviceType;
  name: string;
  roomName?: string | null;
  subdivisionId: string;
}

export interface ConnectEnerIqEconomicUnitInput {
  enerIqApiId: VendorApiId;
  heatingSystemId: HeatingSystemId;
}

export interface ConnectEnerIqMeasurementInput {
  enerIqEconomicUnitId: EnerIqConnectedEconomicUnitId;
  group: number;
  heatingSystemMeasurementId: HeatingSystemMeasurementId;
  sourceType: EnerIqMeasurementSourceType;
  systemComponentType: EnerIqMeasurementSystemComponentType;
}

export interface ConnectNukiSmartlockInput {
  name: string;
  nukiApiId: VendorApiId;
  parentId: string;
  smartlockId: string;
}

export interface ConnectViessmannDeviceInput {
  deviceId: string;
  gatewaySerial: string;
  installationId: number;
  name: string;
  parentId: string;
}

export interface ConnectViessmannInstallationInput {
  consumerIds: string[];
  installationId: number;
  name: string;
  siteId: string;
  viessmannApiId: VendorApiId;
}

export interface CreateBuildingsInput {
  projectReference: string;
  updatedBuildingReferenceIds?: string[] | null;
}

export interface CreateDormakabaVisitInput {
  code: DormakabaVisitCode;
  phoneNumber: PhoneNumber;
  spotId: string;
  validFrom?: RelativeDateTime | null;
  validTo?: RelativeDateTime | null;
}

export interface CreateProjectInput {
  customerId?: string | null;
  objectList: ProjectObjectListItem[];
  salesContactUserName?: string | null;
  serviceProviderId?: string | null;
}

export interface CustomerContact {
  email?: string | null;
  firstName: string;
  phoneNumber?: string | null;
  surname: string;
  title?: string | null;
}

export interface DeleteTreeNodeFileInput {
  id: string;
}

export interface DisconnectAareonHeatingSystemInput {
  id: AareonConnectedHeatingSystemId;
}

export interface DisconnectEnerIqEconomicUnitInput {
  id: EnerIqConnectedEconomicUnitId;
}

export interface DisconnectEnerIqMeasurementInput {
  id: EnerIqMeasurementId;
}

export interface DisconnectNukiSmartlockInput {
  smartlockId: number;
}

export interface DisconnectViessmannInstallationInput {
  installationId: number;
}

export interface EditAareonApiInput {
  aareonApiId: VendorApiId;
  kafkaTopic: string;
}

export interface EditActivityCheckInScheduleInput {
  amount: number;
  daysOfWeek?: number[] | null;
  endDate?: DateTime | null;
  id: ActivityCheckInScheduleId;
  notifyOnMissedLogEntry: boolean;
  startDate: DateTime;
  treeNodeIds: string[];
  treeNodeType: TreeNodeType;
  unit: IntervalUnit;
}

export interface EditActivityInput {
  checkOutInterval?: Duration | null;
  id: ActivityId;
  name: string;
}

export interface EditAlertMailConfigurationInput {
  alertCategoryIds: AlertCategoryId[];
  alertMailConfigurationId: VendorApiId;
  apartmentReferenceAttributeDefinitionId?: string | null;
  companyReferenceAttributeDefinitionId?: string | null;
  economicUnitReferenceAttributeDefinitionId?: string | null;
  emailAddress?: string | null;
  enabled: boolean;
  gatewayHealths: GatewayHealth[];
  heatingSystem: boolean;
  label: string;
  missedActivityLogEntry: boolean;
  propertyReferenceAttributeDefinitionId?: string | null;
  spotHealths: SpotHealth[];
  treeNodeIds: string[];
}

export interface EditAlertRuleDeploymentInput {
  categoryId: AlertCategoryId;
  enabled: boolean;
  id: AlertRuleDeploymentId;
  name: string;
  notes?: string | null;
  notificationBehavior: AlertNotificationBehavior;
  spotFilterIds: string[];
  spotFilterRoles: string[];
  variables: EditAlertRuleDeploymentVariableInput[];
}

export interface EditAlertRuleDeploymentVariableInput {
  name: string;
  value?: JSON | null;
}

export interface EditAlertRuleInput {
  allowedCustomerIds?: string[] | null;
  applicableSpotRoles: string[];
  description?: string | null;
  enabled: boolean;
  id: AlertRuleId;
  name: string;
  notes?: string | null;
  selectable: boolean;
  variableDefinitions: EditAlertRuleVariableDefinitionInput[];
}

export interface EditAlertRuleVariableDefinitionInput {
  defaultValue?: JSON | null;
  inputDescription?: string | null;
  inputLabel?: string | null;
  inputOptions?: EditAlertRuleVariableDefinitionInputOptionInput[] | null;
  inputType?: string | null;
  name: string;
  required?: boolean | null;
}

export interface EditAlertRuleVariableDefinitionInputOptionInput {
  label: string;
  value?: JSON | null;
}

export interface EditAttributeDefinitionInput {
  calculations?: JSONObject | null;
  description?: string | null;
  id: string;
  inheritableTreeNodeTypes?: TreeNodeType[] | null;
  name: string;
  options?: string[] | null;
  treeNodeTypes: TreeNodeType[];
}

export interface EditAttributeDefinitionTemplateInput {
  addAttributeOnCustomerAdded: boolean;
  description?: string | null;
  id: AttributeDefinitionTemplateId;
  name: string;
  options?: string[] | null;
  treeNodeTypes: TreeNodeType[];
}

export interface EditAufzugheldenApiInput {
  aufzugheldenApiId: VendorApiId;
  password: string;
  username: string;
}

export interface EditComgyApiInput {
  comgyApiId: VendorApiId;
  label: string;
  token: string;
}

export interface EditConsumptionDataExchangeConfigurationInput {
  consent: boolean;
  consumptionDataExchangeConfigurationId: VendorApiId;
  label: string;
  treeNodeIds: string[];
}

export interface EditContractInput {
  apartmentCount?: number | null;
  businessContactId?: string | null;
  companyAddress?: AddressInput | null;
  companyName?: string | null;
  endDate?: DateTime | null;
  externalId: string;
  id: string;
  offer?: string | null;
  salesPartner?: string | null;
  signingDate?: DateTime | null;
  startDate?: DateTime | null;
}

export interface EditCustomerInput {
  businessContactId?: string | null;
  externalId?: string | null;
  id: string;
  name: string;
}

export interface EditDirectoryInput {
  externalCustomerId?: string | null;
  externalId?: string | null;
  id: string;
  name: string;
  order?: number | null;
  technicalContactId?: string | null;
}

export interface EditEnerIqApiInput {
  enerIqApiId: VendorApiId;
  maxConnectedHeatingSystems?: number | null;
  treeNodeIds?: string[] | null;
  yearOfConstructionAttributeDefinitionId?: string | null;
  yearOfRestorationOfFacadeAttributeDefinitionId?: string | null;
  yearOfRestorationOfRoofAttributeDefinitionId?: string | null;
  yearOfRestorationOfWindowsAttributeDefinitionId?: string | null;
}

export interface EditEnerIqMeasurementInput {
  enerIqMeasurementId: EnerIqMeasurementId;
  group?: number | null;
  sourceType?: EnerIqMeasurementSourceType | null;
  systemComponentType?: EnerIqMeasurementSystemComponentType | null;
}

export interface EditExportPresetInput {
  exportPresetType: ExportPresetType;
  id: string;
  mimeType: string;
  name: string;
  query: string;
  template: string;
  timeframes: TimeframeType[];
}

export interface EditExternalSftpConfigurationInput {
  externalSftpConfigurationId: VendorApiId;
  host: string;
  password?: string | null;
  port: number;
  username: string;
}

export interface EditFlatCodeInput {
  accessDate?: DateTime | null;
  id: string;
}

export interface EditFormInput {
  attributeDefinitionIds: string[];
  description?: string | null;
  id: FormId;
  title: string;
}

export interface EditGK40HeizungsprofiConfigurationInput {
  consent: boolean;
  gk40HeizungsprofiConfigurationId: VendorApiId;
  label: string;
  treeNodeIds: string[];
}

/**
 * The data object containing new values for the group, referenced by id.
 */
export interface EditGroupInput {
  id: string;
  name?: string | null;
  permissions?: GroupRolePermission[] | null;
  roles?: GroupRole[] | null;
}

export interface EditHeatingSystemInput {
  boilerDevice?: string | null;
  boilerManufacturer?: string | null;
  boilerModel?: string | null;
  consumptions?: HeatingSystemConsumptionInput[] | null;
  energyOutputRange?: string | null;
  heatingType?: string | null;
  id: string;
  lastHydraulicBalancing?: number | null;
  maintenanceCompany?: string | null;
  manufacturer?: string | null;
  name: string;
  numApartmentUnits?: number | null;
  numCommercialUnits?: number | null;
  productSerial?: string | null;
  suppliedQuantity?: number | null;
  waterStorageCapacity?: string | null;
  waterStorageManufacturer?: string | null;
  waterStorageModel?: string | null;
  waterStorageType?: string | null;
}

export interface EditHeatingSystemMeasurementInput {
  id: string;
  name?: string | null;
}

export interface EditIvmApiInput {
  alertCategoryIds: AlertCategoryId[];
  apartmentReferenceAttributeDefinitionId?: string | null;
  economicUnitReferenceAttributeDefinitionId?: string | null;
  enabled: boolean;
  gatewayHealths: GatewayHealth[];
  heatingSystem: boolean;
  ivmApiId: VendorApiId;
  label: string;
  missedActivityLogEntry: boolean;
  propertyReferenceAttributeDefinitionId?: string | null;
  spotHealths: SpotHealth[];
  treeNodeIds: string[];
  webhookUrl?: string | null;
}

/**
 * The data object containing new values for the user, referenced by id.
 */
export interface EditMyEmailInput {
  email: string;
}

/**
 * The data object containing new values for the user, referenced by id.
 */
export interface EditMyPersonalDataInput {
  firstName: string;
  lastName: string;
}

export interface EditMyPhoneNumberInput {
  phoneNumber?: PhoneNumber | null;
}

export interface EditPhoneNumberInput {
  id: string;
  phoneNumber?: PhoneNumber | null;
}

export interface EditPropertyGroupInput {
  bgswid?: string | null;
  classification?: string | null;
  externalId?: string | null;
  floors?: PropertyFloorInput[] | null;
  id: string;
  inventory?: InventoryInput | null;
  name: string;
  order?: number | null;
  technicalContactId?: string | null;
}

export interface EditPropertyInput {
  address?: AddressInput | null;
  bgswid?: string | null;
  classification?: string | null;
  externalId?: string | null;
  floors?: PropertyFloorInput[] | null;
  id: string;
  inventory?: InventoryInput | null;
  location?: LocationInput | null;
  name: string;
  order?: number | null;
  size?: number | null;
  technicalContactId?: string | null;
}

export interface EditPropertySubdivisionInput {
  externalId?: string | null;
  externalType?: string | null;
  floorLevel?: number | null;
  id: string;
  inventory?: InventoryInput | null;
  name: string;
  order?: number | null;
  position?: string | null;
  rooms?: EditPropertySubdivisionRoomInput[] | null;
  size?: number | null;
  technicalContactId?: string | null;
  visualizationHint?: PropertySubdivisionVisualizationHint | null;
}

export interface EditPropertySubdivisionRoomInput {
  name: string;
}

export interface EditRootDirectoryInput {
  id: string;
  name: string;
  technicalContactId?: string | null;
}

export interface EditServiceProviderAuthMethodInput {
  type: ServiceProviderAuthMethod;
  value: string;
}

export interface EditServiceProviderInput {
  auth?: EditServiceProviderAuthMethodInput | null;
  checkOutInterval?: Duration | null;
  email?: string | null;
  firstName?: string | null;
  id: ServiceProviderId;
  lastName?: string | null;
  name: string;
  phoneNumber?: string | null;
  treeNodeIds: string[];
}

export interface EditSpotInput {
  metricConfigurations: EditSpotMetricConfigurationInput[];
  name: string;
  notes?: string | null;
  order?: number | null;
  roomName?: string | null;
  spotId: string;
}

export interface EditSpotMetricConfigurationInput {
  label?: string | null;
  name: string;
  position?: number | null;
  shortLabel?: string | null;
}

export interface EditTreeNodeInput {
  classification?: string | null;
  id: string;
  name: string;
  order?: number | null;
}

export interface EditUserCustomerAdminRoleInput {
  customerAdmin: boolean;
  id: string;
}

/**
 * The data object containing new values for the user, referenced by id.
 */
export interface EditUserInput {
  email?: string | null;
  firstName: string;
  id: string;
  lastName: string;
}

export interface EditUserTechnicianRoleInput {
  id: string;
  technician: boolean;
}

export interface EditViessmannApiInput {
  label: string;
  refreshToken: string;
  viessmannApiId: VendorApiId;
}

export interface HeatingSystemConsumptionInput {
  amount: number;
  energySource: EnergySource;
  year: number;
}

export interface InventoryInput {
  items?: InventoryItemInput[] | null;
}

export interface InventoryItemInput {
  count: number;
  name: string;
}

export interface LocationInput {
  latitude: number;
  longitude: number;
}

export interface LockNukiSmartlockInput {
  spotId: string;
}

export interface MarkNotificationsAsReadInput {
  contextIds?: string[] | null;
  notificationIds?: string[] | null;
  types?: NotificationType[] | null;
  unread?: boolean | null;
  until?: DateTime | null;
  userId?: string | null;
}

/**
 * The following fields only affect the `Metric.points` field:
 * `resolution`, `order`, `skip`, `take`.
 */
export interface MetricDescriptorInput {
  name?: string | null;
  order?: Order | null;
  reference?: string | null;
  resolution?: MetricResolutionInput | null;
  skip?: number | null;
  start?: RelativeDateTime | null;
  stop?: RelativeDateTime | null;
  take?: number | null;
}

export interface MetricResolutionInput {
  aggregator?: MetricResolutionAggregator | null;
  fill?: MetricResolutionFill | null;
  intervalLength: Duration;
  timeSource?: MetricResolutionTimeSource | null;
}

export interface MetricSpotRoleAggregationDescriptorInput {
  aggregator: MetricSpotAggregator;
  name: string;
  reference?: string | null;
  role: string;
  start?: RelativeDateTime | null;
  stop?: RelativeDateTime | null;
}

export interface MountAufzugheldenDeviceInput {
  accountId: string;
  aufzugheldenApiId: VendorApiId;
  deviceId: string;
  spotId: string;
}

export interface MountDeviceInput {
  actAquaSystemId?: string | null;
  attributes?: MountDeviceInputAttributes[] | null;
  deviceAddress?: string | null;
  encryptionKey?: string | null;
  gatewayId?: string | null;
  initialMeterReading?: number | null;
  istaPropertyId?: string | null;
  manual?: boolean | null;
  manufacturer?: string | null;
  modelNumber?: string | null;
  notes?: string | null;
  pulsePerUnit?: number | null;
  serial?: string | null;
  slaveAddress?: string | null;
  spotId: string;
}

export interface MountDeviceInputAttributes {
  name: string;
  value: string;
}

export interface MoveToTreeNodeGroupInput {
  id: string;
  treeNodeGroupId: string;
}

export interface MoveTreeNodeGroupInput {
  id: string;
  parentId: string;
}

export interface PlaceGroundPlanSpotInput {
  position?: RelativePositionInput | null;
  spotId: string;
  treeNodeFileId: string;
}

export interface ProjectObjectListItem {
  accessType?: string | null;
  buildingReferenceId: string;
  buildingType?: TreeNodeClassification | null;
  cityAddress: string;
  customerContact?: CustomerContact | null;
  customizedId?: string | null;
  houseAddress: string;
  itemName: string;
  numberOfApartments?: number | null;
  objectType: TreeNodeType;
  postCodeAddress: string;
  productType?: string | null;
  streetAddress: string;
  useCase?: string | null;
}

export interface PropertyFloorInput {
  level: number;
  name?: string | null;
  type?: PropertyFloorType | null;
}

export interface RebootGatewayInput {
  serial: string;
}

export interface RelativePositionInput {
  x: number;
  y: number;
}

export interface RemoveAareonApiInput {
  aareonApiId: VendorApiId;
}

export interface RemoveActivityInput {
  id: ActivityId;
}

export interface RemoveActivityLogEntriesInput {
  ids: ActivityLogEntryId[];
}

export interface RemoveAlertMailConfigurationInput {
  alertMailConfigurationId: VendorApiId;
}

export interface RemoveAlertRuleDeploymentInput {
  id: AlertRuleDeploymentId;
}

export interface RemoveAttributeDefinitionInput {
  id: string;
}

export interface RemoveAttributeDefinitionTemplateInput {
  id: AttributeDefinitionTemplateId;
}

export interface RemoveAufzugheldenApiInput {
  aufzugheldenApiId: VendorApiId;
}

export interface RemoveComgyApiInput {
  comgyApiId: VendorApiId;
}

export interface RemoveConsumptionDataExchangeConfigurationInput {
  consumptionDataExchangeConfigurationId: VendorApiId;
}

export interface RemoveEnerIqApiInput {
  enerIqApiId: VendorApiId;
}

export interface RemoveExportConfigurationInput {
  exportConfigurationId: VendorApiId;
}

export interface RemoveExportPresetInput {
  id: string;
}

export interface RemoveExternalSftpConfigurationInput {
  externalSftpConfigurationId: VendorApiId;
}

export interface RemoveFlatCodesInput {
  ids: FlatCodeId[];
}

export interface RemoveFromTreeNodeGroupInput {
  id: string;
}

export interface RemoveGK40HeizungsprofiConfigurationInput {
  gk40HeizungsprofiConfigurationId: VendorApiId;
}

export interface RemoveGroupInput {
  id: string;
}

export interface RemoveHeatingSystemConsumerInput {
  consumerId: string;
  heatingSystemId: string;
}

export interface RemoveHeatingSystemInput {
  id: string;
}

export interface RemoveHeatingSystemMeasurementInput {
  id: string;
}

export interface RemoveIvmApiInput {
  ivmApiId: VendorApiId;
}

export interface RemoveNukiApiInput {
  nukiApiId: VendorApiId;
}

export interface RemoveServiceProvidersActivityInput {
  activityId: string;
  serviceProviderId: string;
}

export interface RemoveSpotInput {
  id: string;
}

export interface RemoveSuite5CodeInput {
  id: Suite5CodeId;
}

export interface RemoveTreeNodeInput {
  ids: string[];
}

export interface RemoveViessmannApiInput {
  viessmannApiId: VendorApiId;
}

export interface ResetAlertInput {
  ruleDeploymentId: AlertRuleDeploymentId;
  spotId: string;
  start?: RelativeDateTime | null;
}

/**
 * The data object containing the email for which to send a password reset request to keycloak.
 */
export interface ResetPasswordInput {
  email: string;
}

export interface ResolveNotificationInput {
  notes?: string | null;
  notificationId: string;
  reset?: boolean | null;
}

export interface SaveProjectDraftInput {
  pageContent?: JSONObject | null;
  pageReference?: string | null;
  projectReference: string;
}

export interface SendHelpMailInput {
  email: string;
  message: string;
  subject: string;
  type: string;
}

export interface SetActivityFormsFormInput {
  formId: FormId;
  treeNodeIds: string[];
}

export interface SetActivityFormsInput {
  forms: SetActivityFormsFormInput[];
  id: ActivityId;
}

export interface SetActivityLogEntryAttributeInput {
  activityLogEntryId: ActivityLogEntryId;
  attributeDefinitionName: string;
  value: string;
}

export interface SetEnerIqEconomicUnitConfigurationInput {
  configuration?: JSONObject | null;
  id: EnerIqConnectedEconomicUnitId;
}

/**
 * See interface `GatewaySettings` for a description of the available
 * settings.
 */
export interface SetGatewayConfigurationPresetInput {
  accessPointExternalEnabled?: boolean | null;
  accessPointExternalPreSharedKey?: string | null;
  accessPointExternalSsid?: string | null;
  apply?: boolean | null;
  lightBrightness?: number | null;
  lightDuration?: number | null;
  lightEmergencyBrightness?: number | null;
  lightEmergencyInterval?: number | null;
  lightEmergencyTimeout?: number | null;
  lightPingInterval?: number | null;
  treeNodeId: string;
}

export interface SetGroundPlanFileInput {
  treeNodeFileId?: string | null;
  treeNodeId: string;
}

export interface SetPermissionsForUserInput {
  permissions: SetPermissionsForUserPermissionInput[];
  user: string;
}

export interface SetPermissionsForUserPermissionInput {
  resource: string;
  resourceType: string;
  scopes: string[];
}

export interface SetRestrictedDeviceManufacturerPermissionsForUserInput {
  deviceRoles: string[];
  manufacturers: string[];
  userId: string;
}

export interface SetTreeNodeAttributeInput {
  attributeDefinitionName: string;
  treeNodeId: string;
  value: string;
}

export interface SetUserGroupsInput {
  groupIds: string[];
  userId: string;
}

export interface SpotAttributeFilterInput {
  attributeDefinitionId: string;
  values: string[];
}

export interface StartNukiApiAuthenticationInput {
  nukiApiId: VendorApiId;
  redirectUrl: string;
}

export interface SwitchLampInput {
  command: LampCommandName;
  local?: boolean | null;
  serial: string;
}

export interface SwitchOffIOXSmartPlugInput {
  spotId: string;
}

export interface SwitchOnIOXSmartPlugInput {
  spotId: string;
}

export interface UnconfigureAlertRuleStrategyInput {
  id: AlertRuleId;
}

export interface UnlockDormakabaComponentInput {
  spotId: string;
}

export interface UnlockNukiSmartlockInput {
  spotId: string;
}

export interface UnmountAufzugheldenDeviceInput {
  deviceId: string;
}

export interface UnmountDeviceInput {
  id: string;
}

export interface UpdateProjectBuildingsStatusesInput {
  projectReference: string;
  status: EnterpriseProjectBuildingStatus;
}

export interface UpdateProjectInput {
  projectReference: string;
  serviceProviderId?: string | null;
}

export interface UpdateProjectObjectListItemStatusInput {
  buildingReferenceId?: string | null;
  id?: string | null;
  projectReference?: string | null;
  status: EnterpriseProjectBuildingStatus;
  updates?: ValueUpdate | null;
}

export interface UpdateProjectStatusInput {
  projectReference: string;
  senderEmail?: string | null;
  status: EnterpriseProjectStatus;
}

export interface UploadTreeNodeFileInput {
  file: Upload;
  label?: string | null;
  treeNodeId: string;
}

export interface ValueUpdate {
  ccrReferenceId?: string | null;
  customerNumber?: string | null;
  externalId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
