import { Module, MutationTree, ActionTree, GetterTree, Commit, Dispatch } from 'vuex';
import { RootStore } from '@/features/core/store';
import {
  BuildingFormData,
  parseBuildingFormData,
  parseFormData,
  UseCasesFormData,
} from '@/features/core/store/page-modules/enterprise-project-details/property-building-edit-helpers';
import { DollarApollo } from 'vue-apollo/types/vue-apollo';
import Vue from 'vue';
import createBuildings from '@/features/app-enterprise-project/views/enterprise-project-create/create-buildings-mutation.gql';
import { CreateBuildingsMutationVariables } from '@/features/app-enterprise-project/views/enterprise-project-create/__generated__/CreateBuildingsMutation';
import saveProjectDraft from '@/features/app-enterprise-project/views/enterprise-project-create/save-project-draft.gql';
import { SaveProjectDraftMutationVariables } from '@/features/app-enterprise-project/views/enterprise-project-create/__generated__/SaveProjectDraftMutation';
import { transformStateForSaving } from '@/features/core/store/page-modules/enterprise-project-create/create-module-helpers';
import {
  DatabaseProjectDraft,
  NewProjectData,
  ProjectBuilding,
  ProjectDraftCompleted,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import { PropertyEditState } from '@/features/core/store/page-modules/enterprise-project-details/property-building-types';

// Initial state of the project
const state: PropertyEditState = {
  tab: 'step1',
  buildingReferenceId: null,
  buildingInformation: null,
  projectDraftData: null,
};

// Mutations
const mutations: MutationTree<PropertyEditState> = {
  setPropertyEditTab(state, tab: 'step1' | 'step2' | 'step3' | 'preview') {
    state.tab = tab;
  },
  setBuildingEditInformation(state, buildingInformation: ProjectBuilding) {
    state.buildingInformation = buildingInformation;
  },
  setProjectDraftData(state, projectDraftData: NewProjectData) {
    state.projectDraftData = projectDraftData;
  },
  setBuildingReferenceId(state, buildingReferenceId: string) {
    state.buildingReferenceId = buildingReferenceId;
  },
};

type SavePropertyBuildingInput = {
  formData: BuildingFormData & UseCasesFormData;
  apolloClient: DollarApollo<Vue>;
};

// Actions
const actions: ActionTree<PropertyEditState, any> = {
  async savePropertyBuilding({ state, commit }, params: SavePropertyBuildingInput) {
    commit('setSaving', true);
    const updatedProjectDraftData = state.projectDraftData;
    if (
      updatedProjectDraftData === null ||
      !state.buildingInformation?.id ||
      !state.projectDraftData?.projectReference
    ) {
      return;
    }
    // We need to parse the information back to the BuildingInformation format
    const parsedBuilding = parseBuildingFormData(params.formData, state.buildingInformation);
    // Put the parsed building information back to the projectDraftData
    for (let i = 0; i < updatedProjectDraftData.buildings.length; i++) {
      if (updatedProjectDraftData.buildings[i].id === parsedBuilding.id) {
        updatedProjectDraftData.buildings[i] = parsedBuilding;
      }
    }
    // First convert the state data
    let transformedObject = transformStateForSaving(updatedProjectDraftData);
    // Add the completed values
    transformedObject = {
      ...transformedObject,
      generalInformationCompleted: true,
      buildingsImportCompleted: true,
      propertyInformationCompleted: true,
      propertyAssociationCompleted: true,
    } as DatabaseProjectDraft & ProjectDraftCompleted;

    const { errors: draftSaveErrors } = await params.apolloClient.mutate({
      mutation: saveProjectDraft,
      variables: {
        input: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          pageContent: transformedObject as any,
          pageReference: 'full',
          projectReference: transformedObject.projectReference,
        },
      } as SaveProjectDraftMutationVariables,
    });
    if (state.buildingReferenceId) {
      const updatedBuildingReferenceIds: string[] = [state.buildingReferenceId.toString()];
      const { errors } = await params.apolloClient.mutate({
        mutation: createBuildings,
        variables: {
          input: {
            projectReference: state.projectDraftData?.projectReference,
            updatedBuildingReferenceIds,
          },
        } as CreateBuildingsMutationVariables,
      });
    }
    commit('setSaving', false);
  },
};

// Getters
const getters: GetterTree<PropertyEditState, RootStore> = {
  // A getter to get the information from the draft data and building
  getFormData(state) {
    if (state.projectDraftData && state.buildingInformation) {
      return parseFormData(state.projectDraftData.general, state.buildingInformation);
    }
  },
};

// Export the module
export const EnterprisePropertyEditModule: Module<PropertyEditState, any> = {
  state,
  mutations,
  actions,
  getters,
};
