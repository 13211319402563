import {
  DatabaseProjectDraft,
  InfrastructureVariantType,
  IWCloudType,
  NewProjectData,
  NewProjectGeneralData,
  ProjectBuilding,
  ProjectDraftBuilding,
  ProjectDraftCompleted,
  ProjectDraftMainBuilding,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import { GetProjectDetailsQuery_projectDetails_items } from '@/features/app-enterprise-project/views/enterprise-project-details/__generated__/GetProjectDetailsQuery';
import { EnterpriseProjectObjectUseCase } from '@/features/app-enterprise-project/enterprise-project-constants';
import { EnterpriseProjectObjectProductOption } from '@/types/iot-portal';

function getDraftBuildingName(building: ProjectDraftMainBuilding | ProjectDraftBuilding | ProjectBuilding): string {
  return `${building.streetAddress} ${building.houseAddress} ${
    building.houseAddressSuffix ? building.houseAddressSuffix : ''
  }`;
}

function getProjectItemBuildingName(building: GetProjectDetailsQuery_projectDetails_items): string {
  return `${building.streetAddress} ${building.houseAddress} ${
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    building.houseAddressSuffix ? building.houseAddressSuffix : ''
  }`;
}

// Get a building by its name from db object
export function getBuildingByName(
  object: NewProjectData & ProjectDraftCompleted,
  propertyBuilding: GetProjectDetailsQuery_projectDetails_items,
): ProjectBuilding | undefined {
  let building: ProjectBuilding | undefined = undefined;
  building = object.buildings.find((b) => getDraftBuildingName(b) === getProjectItemBuildingName(propertyBuilding));
  if (building) return building;
  return;
}

export type BuildingFormData = {
  customerName: string | null;
  customerNumber: string | null;
  partnerName: string | null;
  invoiceNumber: string | null;
  invoiceName: string;
  installationStartDate: string;
  serviceLevel: string;
  preInspection: boolean;
  title: string;
  firstName: string;
  surname: string;
  phoneNumber: string;
  email: string;
  optionInfrastructure:
    | InfrastructureVariantType
    | IWCloudType
    | EnterpriseProjectObjectProductOption
    | null
    | undefined;
  monthlyPriceInEurosInfrastructure: number | null | undefined;
  oneTimePriceInEurosInfrastructure: number | null | undefined;
  optionIWCloud: EnterpriseProjectObjectProductOption | InfrastructureVariantType | IWCloudType | null | undefined;
  monthlyPriceInEurosIWCloud: number | null | undefined;
  oneTimePriceInEurosIWCloud: number | null | undefined;
};

export type UseCasesFormData = {
  // Auswahlprodukte Section
  useCaseTypeHeizung?: EnterpriseProjectObjectUseCase | undefined | null; // selected option for Heizung use case type
  monthlyPriceInEurosHeizung?: number | null | undefined; // monthly price for Heizung
  oneTimePriceInEurosHeizung?: number | null | undefined; // one-time price for Heizung

  useCaseTypeAufzug?: EnterpriseProjectObjectUseCase | undefined | null; // selected option for Aufzug use case type
  monthlyPriceInEurosAufzug?: number | null | undefined; // monthly price for Aufzug
  oneTimePriceInEurosAufzug?: number | null | undefined; // one-time price for Aufzug

  // TGA Section
  tgaDigitization: boolean; // checkbox for Digitalisierung TGA (DTGA)
  oneTimePriceInEurosTgaDigitization?: number | null | undefined; // one-time price for TGA Digitalisierung

  tgaMonitoring: boolean; // checkbox for TGA Monitoring (TGAM)
  monthlyPriceInEurosTgaMonitoring?: number | null | undefined; // monthly price for TGA Monitoring (TGAM)
  oneTimePriceInEurosTgaMonitoring?: number | null | undefined; // one-time price for TGA Monitoring (TGAM)

  tgaIntegration: boolean; // checkbox for TGA Integration
  monthlyPriceInEurosTgaIntegration?: number | null | undefined; // monthly price for TGA Integration
  oneTimePriceInEurosTgaIntegration?: number | null | undefined; // one-time price for TGA Integration

  // Andere Section
  remoteMeterReading: boolean; // checkbox for Zählerfernauslesung (Remote Meter Reading)
  monthlyPriceInEurosRemoteMeterReading?: number | null | undefined; // monthly price for Remote Meter Reading
  oneTimePriceInEurosRemoteMeterReading?: number | null | undefined; // one-time price for Remote Meter Reading

  serviceManagement: boolean; // checkbox for SMG (Service Management)
  monthlyPriceInEurosServiceManagement?: number | null | undefined; // monthly price for Service Management
  oneTimePriceInEurosServiceManagement?: number | null | undefined; // one-time price for Service Management

  smg: boolean; // checkbox for Dienstleistungsmanagement (SMG)
  monthlyPriceInEurosSmg?: number | null | undefined; // monthly price for SMG
  oneTimePriceInEurosSmg?: number | null | undefined; // one-time price for SMG

  consumptionDataAcquisition: boolean; // checkbox for Verbrauchsdatenerfassung (Consumption Data Acquisition)
  monthlyPriceInEurosConsumptionDataAcquisition?: number | null | undefined; // monthly price for Consumption Data Acquisition
  oneTimePriceInEurosConsumptionDataAcquisition?: number | null | undefined; // one-time price for Consumption Data Acquisition

  consumptionInformationDuringTheYear: boolean; // checkbox for unterjährige Verbrauchsinformation (Consumption Information During the Year)
  monthlyPriceInEurosConsumptionInformationDuringTheYear?: number | null | undefined; // monthly price for Consumption Information During the Year
  oneTimePriceInEurosConsumptionInformationDuringTheYear?: number | null | undefined; // one-time price for Consumption Information During the Year

  smokeAlarmMonitoring: boolean; // checkbox for Rauchwarnmeldermonitoring (Smoke Alarm Monitoring)
  monthlyPriceInEurosSmokeAlarmMonitoring?: number | null | undefined; // monthly price for Smoke Alarm Monitoring
  oneTimePriceInEurosSmokeAlarmMonitoring?: number | null | undefined; // one-time price for Smoke Alarm Monitoring
};

// eslint-disable-next-line complexity
function getUseCasesForFormData(buildingInformation: ProjectBuilding): UseCasesFormData {
  let useCasesFormData: UseCasesFormData = {
    tgaDigitization: false,
    tgaMonitoring: false,
    tgaIntegration: false,
    remoteMeterReading: false,
    serviceManagement: false,
    smg: false,
    consumptionDataAcquisition: false,
    consumptionInformationDuringTheYear: false,
    smokeAlarmMonitoring: false,
  };
  if (buildingInformation?.useCases) {
    const useCases = buildingInformation?.useCases;
    for (let i = 0; i < useCases.length; i++) {
      if (useCases[i].label === null || useCases[i].label === undefined) {
        continue;
      }
      if (
        useCases[i].useCaseType === EnterpriseProjectObjectUseCase.HEATING_MONITORING ||
        useCases[i].useCaseType === EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL
      ) {
        // if (useCases[i].label?.toLowerCase() === 'heizung') {
        useCasesFormData = {
          ...useCasesFormData,
          useCaseTypeHeizung: useCases[i].useCaseType,
          monthlyPriceInEurosHeizung: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosHeizung: useCases[i].oneTimePriceInEuros,
        };
      }
      if (
        useCases[i].useCaseType === EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING ||
        useCases[i].useCaseType === EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE
      ) {
        useCasesFormData = {
          ...useCasesFormData,
          useCaseTypeAufzug: useCases[i].useCaseType,
          monthlyPriceInEurosAufzug: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosAufzug: useCases[i].oneTimePriceInEuros,
        };
      }
      if (useCases[i].useCaseType === EnterpriseProjectObjectUseCase.TGA_DIGITIZATION) {
        useCasesFormData = {
          ...useCasesFormData,
          tgaDigitization: !!useCases[i].checked,
          oneTimePriceInEurosTgaDigitization: useCases[i].oneTimePriceInEuros,
        };
      }
      if (useCases[i].useCaseType === EnterpriseProjectObjectUseCase.TGA_MONITORING) {
        useCasesFormData = {
          ...useCasesFormData,
          tgaMonitoring: !!useCases[i].checked,
          monthlyPriceInEurosTgaMonitoring: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosTgaMonitoring: useCases[i].oneTimePriceInEuros,
        };
      }
      if (useCases[i].useCaseType === EnterpriseProjectObjectUseCase.TGA_INTEGRATION) {
        useCasesFormData = {
          ...useCasesFormData,
          tgaIntegration: !!useCases[i].checked,
          monthlyPriceInEurosTgaIntegration: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosTgaIntegration: useCases[i].oneTimePriceInEuros,
        };
      }
      if (useCases[i].useCaseType === EnterpriseProjectObjectUseCase.REMOTE_METER_READING) {
        useCasesFormData = {
          ...useCasesFormData,
          remoteMeterReading: !!useCases[i].checked,
          monthlyPriceInEurosRemoteMeterReading: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosRemoteMeterReading: useCases[i].oneTimePriceInEuros,
        };
      }
      if (useCases[i].useCaseType === EnterpriseProjectObjectUseCase.SMG) {
        useCasesFormData = {
          ...useCasesFormData,
          serviceManagement: !!useCases[i].checked,
          monthlyPriceInEurosServiceManagement: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosServiceManagement: useCases[i].oneTimePriceInEuros,
        };
      }
      if (useCases[i].useCaseType === EnterpriseProjectObjectUseCase.SERVICE_MANAGEMENT) {
        useCasesFormData = {
          ...useCasesFormData,
          smg: !!useCases[i].checked,
          monthlyPriceInEurosSmg: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosSmg: useCases[i].oneTimePriceInEuros,
        };
      }
      if (useCases[i].useCaseType === EnterpriseProjectObjectUseCase.CONSUMPTION_DATA_ACQUISITION) {
        useCasesFormData = {
          ...useCasesFormData,
          consumptionDataAcquisition: !!useCases[i].checked,
          monthlyPriceInEurosConsumptionDataAcquisition: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosConsumptionDataAcquisition: useCases[i].oneTimePriceInEuros,
        };
      }
      if (useCases[i].useCaseType === EnterpriseProjectObjectUseCase.CONSUMPTION_INFORMATION_DURING_THE_YEAR) {
        useCasesFormData = {
          ...useCasesFormData,
          consumptionInformationDuringTheYear: !!useCases[i].checked,
          monthlyPriceInEurosConsumptionInformationDuringTheYear: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosConsumptionInformationDuringTheYear: useCases[i].oneTimePriceInEuros,
        };
      }
      if (useCases[i].useCaseType === EnterpriseProjectObjectUseCase.SMOKE_ALARM_MONITORING) {
        useCasesFormData = {
          ...useCasesFormData,
          smokeAlarmMonitoring: !!useCases[i].checked,
          monthlyPriceInEurosSmokeAlarmMonitoring: useCases[i].monthlyPriceInEuros,
          oneTimePriceInEurosSmokeAlarmMonitoring: useCases[i].oneTimePriceInEuros,
        };
      }
    }
  }
  return useCasesFormData;
}

export function parseFormData(
  generalData: NewProjectGeneralData,
  buildingInformation: ProjectBuilding,
): BuildingFormData {
  let formData: BuildingFormData = {
    title: '',
    firstName: '',
    surname: '',
    phoneNumber: '',
    email: '',
    ...buildingInformation,
    ...buildingInformation.liegenshaftInformation,
    invoiceName: buildingInformation.invoiceName ? buildingInformation.invoiceName : '',
    installationStartDate: buildingInformation.installationStartDate ? buildingInformation.installationStartDate : '',
    serviceLevel: buildingInformation?.serviceLevel ? buildingInformation.serviceLevel : '',
    preInspection: buildingInformation?.preInspection ? buildingInformation.preInspection : false,
    customerName: generalData.customerName,
    customerNumber: generalData.customerNumber,
    partnerName: generalData.partnerName,
    invoiceNumber: generalData.invoiceNumber,
    // Infrastructure
    optionInfrastructure: null,
    monthlyPriceInEurosInfrastructure: null,
    oneTimePriceInEurosInfrastructure: null,
    // I-W Cloud
    optionIWCloud: null,
    monthlyPriceInEurosIWCloud: null,
    oneTimePriceInEurosIWCloud: null,
  };
  // Add the products
  if (buildingInformation?.products) {
    const products = buildingInformation?.products;
    for (let i = 0; i < products.length; i++) {
      if (products[i].productType === 'INFRASTRUCTURE_VARIANT') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        formData = {
          ...formData,
          optionInfrastructure: products[i].option,
          monthlyPriceInEurosInfrastructure: products[i].monthlyPriceInEuros,
          oneTimePriceInEurosInfrastructure: products[i].oneTimePriceInEuros,
        };
      }
      if (products[i].productType === 'IW_CLOUD') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        formData = {
          ...formData,
          optionIWCloud: products[i].option,
          monthlyPriceInEurosIWCloud: products[i].monthlyPriceInEuros,
          oneTimePriceInEurosIWCloud: products[i].oneTimePriceInEuros,
        };
      }
    }
  }
  if (buildingInformation?.liegenshaftInformation) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    formData = {
      ...formData,
      ...buildingInformation.liegenshaftInformation,
    };
  }
  const useCases = getUseCasesForFormData(buildingInformation);
  formData = {
    ...formData,
    ...useCases,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return formData;
}

// eslint-disable-next-line complexity
export function parseBuildingFormData(
  formData: BuildingFormData & UseCasesFormData,
  buildingInformation: ProjectBuilding,
): ProjectBuilding {
  // Handle Liegenshaft Information
  if (buildingInformation?.liegenshaftInformation) {
    buildingInformation.liegenshaftInformation = {
      email: formData.email,
      firstName: formData.firstName,
      phoneNumber: formData.phoneNumber,
      surname: formData.surname,
      title: formData.title,
    };
  }
  // Handle general information
  if (formData.invoiceName) {
    buildingInformation.invoiceName = formData.invoiceName;
  }
  if (formData.installationStartDate) {
    buildingInformation.installationStartDate = formData.installationStartDate;
  }
  if (formData.serviceLevel) {
    buildingInformation.serviceLevel = formData.serviceLevel;
  }
  if (formData.preInspection) {
    buildingInformation.preInspection = formData.preInspection;
  }
  // Handle Use cases
  if (buildingInformation?.useCases) {
    const useCases = buildingInformation?.useCases;
    for (let i = 0; i < buildingInformation?.useCases.length; i++) {
      if (buildingInformation?.useCases[i].label === null || buildingInformation?.useCases[i].label === undefined) {
        continue;
      }
      if (buildingInformation?.useCases[i].label?.toLowerCase() === 'heizung') {
        buildingInformation.useCases[i].useCaseType = formData.useCaseTypeHeizung;
        buildingInformation.useCases[i].monthlyPriceInEuros = formData.monthlyPriceInEurosHeizung;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosHeizung;
      }
      if (buildingInformation?.useCases[i].label?.toLowerCase() === 'aufzug') {
        buildingInformation.useCases[i].useCaseType = formData.useCaseTypeAufzug;
        buildingInformation.useCases[i].monthlyPriceInEuros = formData.monthlyPriceInEurosAufzug;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosAufzug;
      }
      if (useCases[i].label?.toLowerCase() === 'digitalisierung tga (dtga)') {
        buildingInformation.useCases[i].checked = formData.tgaDigitization;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosTgaDigitization;
      }
      if (useCases[i].label?.toLowerCase() === 'tga monitoring (tgam)') {
        buildingInformation.useCases[i].checked = formData.tgaMonitoring;
        buildingInformation.useCases[i].monthlyPriceInEuros = formData.monthlyPriceInEurosTgaMonitoring;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosTgaMonitoring;
      }
      if (useCases[i].label?.toLowerCase() === 'tga integration') {
        buildingInformation.useCases[i].checked = formData.tgaIntegration;
        buildingInformation.useCases[i].monthlyPriceInEuros = formData.monthlyPriceInEurosTgaIntegration;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosTgaIntegration;
      }
      if (useCases[i].label?.toLowerCase() === 'zählerfernauslesung (zfa)') {
        buildingInformation.useCases[i].checked = formData.remoteMeterReading;
        buildingInformation.useCases[i].monthlyPriceInEuros = formData.monthlyPriceInEurosRemoteMeterReading;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosRemoteMeterReading;
      }
      if (useCases[i].label?.toLowerCase() === 'wettbewerbliche messstellenbetrieb (smg)') {
        buildingInformation.useCases[i].checked = formData.serviceManagement;
        buildingInformation.useCases[i].monthlyPriceInEuros = formData.monthlyPriceInEurosServiceManagement;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosServiceManagement;
      }
      if (useCases[i].label?.toLowerCase() === 'dienstleistungsmanagement') {
        buildingInformation.useCases[i].checked = formData.smg;
        buildingInformation.useCases[i].monthlyPriceInEuros = formData.monthlyPriceInEurosSmg;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosSmg;
      }
      if (useCases[i].label?.toLowerCase() === 'verbrauchsdatenerfassung (vde)') {
        buildingInformation.useCases[i].checked = formData.consumptionDataAcquisition;
        buildingInformation.useCases[i].monthlyPriceInEuros = formData.monthlyPriceInEurosConsumptionDataAcquisition;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosConsumptionDataAcquisition;
      }
      if (useCases[i].label?.toLowerCase() === 'unterjährige verbrauchsinformation (uvi)') {
        buildingInformation.useCases[i].checked = formData.consumptionInformationDuringTheYear;
        buildingInformation.useCases[i].monthlyPriceInEuros =
          formData.monthlyPriceInEurosConsumptionInformationDuringTheYear;
        buildingInformation.useCases[i].oneTimePriceInEuros =
          formData.oneTimePriceInEurosConsumptionInformationDuringTheYear;
      }
      if (useCases[i].label?.toLowerCase() === 'rauchwarnmeldermonitoring') {
        buildingInformation.useCases[i].checked = formData.smokeAlarmMonitoring;
        buildingInformation.useCases[i].monthlyPriceInEuros = formData.monthlyPriceInEurosSmokeAlarmMonitoring;
        buildingInformation.useCases[i].oneTimePriceInEuros = formData.oneTimePriceInEurosSmokeAlarmMonitoring;
      }
    }
  }
  // Handle products
  if (buildingInformation?.products) {
    const products = buildingInformation?.products;
    for (let i = 0; i < products.length; i++) {
      if (products[i].productType === 'INFRASTRUCTURE_VARIANT') {
        products[i].option = formData.optionInfrastructure;
        products[i].monthlyPriceInEuros = formData.monthlyPriceInEurosInfrastructure;
        products[i].oneTimePriceInEuros = formData.oneTimePriceInEurosInfrastructure;
      }
      if (products[i].productType === 'IW_CLOUD') {
        products[i].option = formData.optionIWCloud;
        products[i].monthlyPriceInEuros = formData.monthlyPriceInEurosIWCloud;
        products[i].oneTimePriceInEuros = formData.oneTimePriceInEurosIWCloud;
      }
    }
  }
  return buildingInformation;
}
