



































import { BooleanProp } from '@/util/prop-decorators';
import { throttle } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { RootGetter } from '../../store';
import { App } from '../../model';

@Component
export default class LayoutDefault extends Vue {
  @BooleanProp()
  private readonly treeNodeBar!: boolean;

  @BooleanProp()
  private readonly scrollToTop!: boolean;

  @BooleanProp()
  private readonly singlePage!: boolean;

  @RootGetter
  private readonly app?: App;

  private scrolled = false;
  private mainLeaving = false;
  private resizeValue = 0;
  private isActive = false;

  private get treeNodeBarDisabled(): boolean {
    return !this.treeNodeBar || this.app?.generateTreeNodeLocation === undefined || this.showAdvancedView;
  }

  private get mainPanelWidth(): string {
    if (!this.treeNodeBarDisabled) {
      if (window.innerWidth < 3000) {
        const mainPanelWidth = this.resizeValue > 12 ? this.resizeValue - 8 : 4;
        return this.resizeValue < 30 ? mainPanelWidth + '%' : 20 + '%';
      } else {
        const mainPanelWidth = this.resizeValue > 12 ? this.resizeValue - 3 : 3;
        return this.resizeValue < 15 ? mainPanelWidth + '%' : 12 + '%';
      }
    }
    return 'auto';
  }

  private get showAdvancedView(): boolean {
    return this.$store.getters.showAdvancedView;
  }

  private onMouseDown(e: MouseEvent): void {
    if (e.target === document.getElementById('core-tree-node-bar-control')) {
      this.isActive = true;
    }
  }

  private onMouseUp(): void {
    this.isActive = false;
  }

  private onMouseMove(e: MouseEvent): void {
    if (this.isActive) {
      let offset = 0;
      let target = e.currentTarget as HTMLDivElement;
      while (target) {
        offset += target.offsetLeft;
        target = target.offsetParent as HTMLDivElement;
      }

      const currentPage = e.pageX;
      const targetOffset = e.currentTarget as HTMLDivElement;
      const resizeValue = Math.floor(((currentPage - offset) / targetOffset.offsetWidth) * 10000) / 100;

      if (resizeValue < 100) {
        this.resizeValue = resizeValue;
      }
    }
  }

  private doScrollToTop(): void {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  private created(): void {
    const updateScrolled = throttle(
      () => void (this.scrolled = (window.scrollY !== undefined ? window.scrollY : window.pageYOffset) > 76),
      200,
      { leading: true, trailing: true },
    );

    window.addEventListener('scroll', updateScrolled, { passive: true });
    this.$once('hook:destroyed', () => void window.removeEventListener('scroll', updateScrolled));
  }
}
