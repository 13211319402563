import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './heating-control-lg.svg';
import md from './heating-control-md.svg';
import sm from './heating-control-sm.svg';
import component from './HeatingControlDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.HEATING_CONTROL,
  label: 'Heizungssteuerung',
  shortLabel: 'Heizungssteuerung',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['temperature1'],
  importantMetricNames: ['temperature1', 'temperature2'],
}));
